.login_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 15px;
    // background-color: $;
    // color: $white;
    .login_wrapper{
        max-width: 400px;
        width: 100%;
        .login_icon{
            font-size: 40px;
            text-align: center;
            color: #ff0000;
        }
        .title_text{
            text-align: center;
            margin-bottom: 20px;
            h1{
                margin-bottom: 10px;
            
            }
        }
        .form_remember{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .remember{
                margin: 0;
            }
        }
        .login_button{
            width: 100%;
            .ant-btn{
                width: 100%;
            }
        }
        .form_signup{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}