.header_main {
  background: $secondary_color;
  padding: 10px 24px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;

  .logo {
    font-size: 24px;
    color: $primary_color;
    background-color: white;
    padding: 0 15px;
    cursor: pointer;
    transition: 0.3s all;
    box-shadow: 0 1px 4px rgba(255, 255, 255, 0.08);
    span {
      transition: 0.3s all;
    }
    &:hover {
      font-size: 30px;
      padding: 0 12px;

      span {
        transform: rotate(180deg);
      }
    }
  }
  .action_section {
    display: flex;
    align-items: center;
    button{
      background: white;
      color: $primary_color;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        background: darken(white, 10%);
      }
    }
  }
}
