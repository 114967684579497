/* .loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader-wrapper .logo {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.loader-wrapper.animate .logo {
  opacity: 0;
  animation: 0.5s step-end infinite;
}

.loader-wrapper .loader {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.loader-wrapper.animate .loader {
  opacity: 1;
  transform: scale(1);
} */

.bug-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #00000064;

  .logo {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  &.animate {
    .logo {
      opacity: 0;
      animation: 0.5s step-end infinite;
    }

    .loader {
      opacity: 1;
      transform: scale(1);
    }
  }

  .loader {
    position: absolute;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
}

.tabler-icon{
  stroke-width: 1.25 !important;
}