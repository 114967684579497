// Reusable Sass mixins.
@mixin maxSize($width, $height) {
    max-width: $width;
    max-height: $height;
  }
  
  @mixin fontStyle(
    $font-size,
    $font-weight,
    $color,
    // $font-family: "DM Sans" sans-serif
  ) {
    font-size: $font-size;
    font-weight: $font-weight;
    color: $color;
    // font-family: $font-family !important;
  }
  
  @mixin flex($align-items, $justify-content) {
    display: flex;
    align-items: $align-items;
    justify-content: $justify-content;
  }
  
  @mixin mediaResponsive($size) {
    @if $size ==mini {
      @media (max-width: 991px) {
        @content;
      }
    }
    @if $size ==tab {
      @media (max-width: 768px) {
        @content;
      }
    }
    @if $size ==mob {
      @media (max-width: 576px) {
        @content;
      }
    } @else {
      @media (max-width: $size) {
        @content;
      }
    }
  }
  
  @mixin mediaResponsiveHeight($size) {
    @media (max-height: $size) {
      @content;
    }
  }
  