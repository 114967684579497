
.sidebar-lander {
    // background: $background-color-default;
  
    .sidebar-menu {
      height: calc(100vh - 64px);
      overflow: hidden auto;
      // background: $background-color-default;
    }
  
    .ant-layout-sider {
      background: transparent !important;
      position: sticky;
      // right: 0;
      top: 64px;
      z-index: 10 !important;
      .ant-menu-item{
        &.ant-menu-item-selected{
          background-color: $darkred_color;
          color: $primary_color;
        }
      }
    }
    .ant-layout-sider-zero-width-trigger{
      background: $darkred_color;
    }
  
    // @include mediaResponsive(mini) {
    //   .ant-layout-sider {
    //     position: fixed;
    //     // z-index: 999;
    //   }
    // }
  }