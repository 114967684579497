*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:after,
  &:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.container_fluid {
  max-width: 100%;
  width: 100%;
}
img {
  display: block;
}

:focus {
  outline: none;
}

.d_none {
  display: none;
}
.no_scroll {
  overflow: hidden;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
body,
ol,
ul,
li,
div,
p,
span,
form,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
th,
tbody,
tr,
td,
img,
input,
textarea,
dd,
dt,
dl {
  word-wrap: break-word;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
textarea {
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dd,
dt,
dl {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  margin: 0;
  padding: 0;
  color: #ffffffd9;
}
a,
p {
  font-size: 14px;
}
a img,
img {
  border: none;
  outline: none;
  width: auto;
  max-width: 100%;
}
a {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}
li {
  list-style-type: none;
}
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  // background-color: #f0ece1;
  background-color: #0c0c0c;
  // #1a1621;
}
::selection {
  // text-shadow: none;
  // background: rgba(65, 131, 196, 0.4);
}
.clear {
  clear: both;
  height: 0px;
  overflow: hidden;
  width: auto;
  display: block;
  float: none !important;
}
ul,
menu,
dir {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

li:last-child,
p:last-child {
  margin-bottom: 0 !important;
}
button:focus {
  outline: none;
}
select {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

html {
  // min-width: 1350px;
  // overflow: hidden;
}
body {
  //   // min-width: 1350px;
  //   font-family: Tahoma, Helvetica, sans-serif;
  //   font-size: 12px;
  //   color: #000;
  //   line-height: 1.5;
  //   // overflow: hidden;
  //   @include bp(mini) {
  //     font-family: Helvetica, Tahoma, sans-serif;
  //   }
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.small,
small {
  font-size: 0.875em;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.badge {
  display: inline-block;
  padding: 3px 5px;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
}
a {
  text-decoration: none;
  color: #0d6efd;
  &:hover {
    color: #0a58ca;
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: $darkred_color;
  background: $primary_color;
}

::selection {
  color: $darkred_color;
  background: $primary_color;
}

// resizeable table
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

// .ant-table th.ant-table-selection-column, .ant-table td.ant-table-selection-column {
//   max-width: 50px !important;
//   width: 50px !important;

// }

.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: #fffefe00;
  border-radius: 10px;
  margin: 0.8rem;
}
*::-webkit-scrollbar-thumb {
  background: #ff0000;
  border-radius: 10px;
  transform: rotate(-90deg);
}

.full-hight-table {
  .ant-table-container {
    border-inline-start: 0 !important;
  }
  .ant-table-header {
    border-inline-start: 1px solid #303030;
  }
  .ant-table-body {
    height: calc(100vh - 194px) !important;
    table {
      border-inline-start: 1px solid #303030;
    }
    .ant-table-placeholder {
      .ant-table-cell {
        padding: 0px;
        height: calc(100vh - 194px) !important;
        border: none;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
    }
  }
}

.not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .image {
    width: 500px;
    // height: 500px;
  }
}
