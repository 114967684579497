.add_domain_form {
  .add_domain_search_form {
    .add_domain_search_form_fields {
      @include flex(center, flex-start);
      gap: 10px;
      margin-bottom: 15px;
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .add_domain_form_footer {
    @include flex(center, flex-end);
    gap: 10px;
    margin-top: 15px;
  }
}

.table_header {
  @include flex(center, space-between);
  gap: 10px;
  margin-bottom: 15px;
  input {
    width: 300px;
  }
}
